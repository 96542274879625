import { ElectricBolt, Thermostat } from "@mui/icons-material";
import { BottomNavigation, BottomNavigationAction, Box } from "@mui/material";
import { Outlet, Link } from "react-router-dom";

function DefaultLayout() {
  return (
    <>
      <Box>
        <Outlet />
        <BottomNavigation>
          <BottomNavigationAction
            component={Link}
            label="Temperatur"
            to="/"
            icon={<Thermostat />}
          />
          <BottomNavigationAction
            component={Link}
            label="El"
            to="/electricity"
            icon={<ElectricBolt />}
          />
        </BottomNavigation>
      </Box>
    </>
  );
}

export default DefaultLayout;
