import { Box, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

type ElectricityDataList = {
  d: ElectricityData[];
  info: string;
};

type ElectricityData = {
  title: string;
  obis: { [id: string]: ElectricityMeasurement };
};

type ElectricityMeasurement = {
  l: string;
  v: number[];
};

function Electricity() {
  const { status, error, data } = useQuery<ElectricityMeasurement, Error>({
    queryKey: ["electricity"],
    queryFn: getElectricity,
    refetchInterval: 5000,
  });

  async function getElectricity(): Promise<ElectricityMeasurement> {
    const p1ibResponse = await fetch(`https://p1ib.relnah.se/`);
    if (!p1ibResponse.ok) {
      throw new Error(`Problem fetching data: ${p1ibResponse.status}`);
    }
    const electricityDataList: ElectricityDataList = await p1ibResponse.json();
    let momentaryPower: ElectricityMeasurement = { l: "", v: [0] };
    const electricityData: ElectricityData | undefined =
      electricityDataList.d.find((t) => t.obis.hasOwnProperty("1.0.1.7.0.255"));

    if (electricityData !== undefined) {
      momentaryPower = electricityData.obis["1.0.1.7.0.255"];
    }

    return momentaryPower;
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="start"
        minHeight="94vh"
      >
        <Stack>
          <Typography variant="h1">{data?.v[9]} kW</Typography>
          {status === "error" ? error!.message : ""}
        </Stack>
      </Box>
    </>
  );
}

export default Electricity;
