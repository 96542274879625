import { Box, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "ts-luxon";

type TemperatureMeasurement = {
  timeStamp: string;
  value: number;
};

function Temperature() {
  const {
    status: shadeStatus,
    error: shadeError,
    data: shadeData,
  } = useQuery<TemperatureMeasurement, Error>({
    queryKey: ["shadeTemp"],
    queryFn: getShadeTemperature,
  });

  const {
    status: poolStatus,
    error: poolError,
    data: poolData,
  } = useQuery<TemperatureMeasurement, Error>({
    queryKey: ["poolTemp"],
    queryFn: getPoolTemperature,
  });

  async function getShadeTemperature(): Promise<TemperatureMeasurement> {
    const northResponse = await fetch(
      `https://relnahweb.homeip.net/housemonitor/temperature/North%20side`
    );
    if (!northResponse.ok) {
      throw new Error(`Problem fetching data: ${northResponse.status}`);
    }
    const northTemp: TemperatureMeasurement = await northResponse.json();

    const entranceResponse = await fetch(
      `https://relnahweb.homeip.net/housemonitor/temperature/Entrance`
    );
    if (!entranceResponse.ok) {
      throw new Error(`Problem fetching data: ${entranceResponse.status}`);
    }
    const entranceTemp: TemperatureMeasurement = await entranceResponse.json();

    if (northTemp.value < entranceTemp.value) {
      return northTemp;
    }

    return entranceTemp;
  }

  async function getPoolTemperature(): Promise<TemperatureMeasurement> {
    const poolResponse = await fetch(
      `https://relnahweb.homeip.net/housemonitor/temperature/Pool`
    );

    if (!poolResponse.ok) {
      throw new Error(`Problem fetching data: ${poolResponse.status}`);
    }

    const poolTemp: TemperatureMeasurement = await poolResponse.json();
    return poolTemp;
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="start"
        minHeight="94vh"
      >
        <Stack>
          <Typography variant="h1">{shadeData?.value} °C</Typography>
          <Typography variant="body1">
            {DateTime.fromISO(shadeData?.timeStamp!).toRelative()}
          </Typography>
          {shadeStatus === "error" ? shadeError!.message : ""}
          <div>&nbsp;</div>
          <Typography variant="body1">Pool</Typography>
          <Typography variant="h4">{poolData?.value} °C</Typography>
          <Typography variant="body1">
            {DateTime.fromISO(poolData?.timeStamp!).toRelative()}
          </Typography>
          {poolStatus === "error" ? poolError!.message : ""}
        </Stack>
      </Box>
    </>
  );
}

export default Temperature;
