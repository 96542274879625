import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DefaultLayout from "./layouts/DefaultLayout";
import Temperature from "./pages/Temperature";
import Electricity from "./pages/Electricity";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DefaultLayout />}>
          <Route index element={<Temperature />} />
          <Route path="electricity" element={<Electricity />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
